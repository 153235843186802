<template>
  <div>
    <q-carousel
      v-model="slide"
      :autoplay="autoplay"
      infinite
      transition-prev="slide-right"
      transition-next="slide-left"
      swipeable
      animated
      class="carousel"
      style="height: 101vh"
      :thumbnails="thumbnails"
      keepalive
    >
      <q-carousel-slide
        v-for="(image, index) in gallery"
        :key="index"
        :name="index + 1"
        :img-src="require(`@/assets/${image}.jpg`)"
      >
      </q-carousel-slide>
    </q-carousel>
</div>
</template>

<script>
import {ref} from "vue";

export default {
    props: ["gallery", "autoplay", "thumbnails"],
    setup(){
        return{
            slide: ref(1)
        }
    }
}
</script>

<style>

</style>